<template>
	<div class="trialadd">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="试驾"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11"
		placeholder 
		:border="false"
		/>
		
		<!-- 标题 -->
		<div class="trialadd__title">
			客户信息
			<div class="customer__gender">
				<div
					@click="form.gender = 1"
					:class="['customer__gender__man', form.gender == 1 ? 'customer__gender--active' : '']"
				>
					<img src="@/assets/common/icon_man.png">
				</div>
				<div
					@click="form.gender = 0"
					:class="['customer__gender__woman', form.gender != 1 ? 'customer__gender--active' : '']"
				>
					<img src="@/assets/common/icon_woman.png">
				</div>
			</div>
		</div>
		
		<!-- 手机 -->
		<div class="trialadd__phone">
			<img src="@/assets/trial/icon_phone.png">
			<input
				type="number"
				placeholder-style="font-family: HYQiHeiY3-45; color: #878787;"
				placeholder="点击输入手机号"
				v-model="form.mobile"
			/>
		</div>
		
		<!-- 姓名 -->
		<div class="trialadd__user">
			<img src="@/assets/trial/icon_user.png">
			<input
				type="text"
				placeholder-style="font-family: HYQiHeiY3-45; color: #878787;"
				placeholder="点击输入客户姓名（称呼）"
				v-model="form.name"
			/>
		</div>
		
		<!-- 标题 -->
		<div style="height: 50px;"></div>
		<div class="trialadd__title">
			试驾车型
		</div>
		<!-- 车型 -->
		<div class="trialadd__carmodel" @click="handCarModelShow">
			<img src="@/assets/trial/icon_car.png">
			{{
				modelName == '' && collocationName == '' ? 
				'点击选择意向车型配置' : 
				`${modelName}${collocationName}`
			}}
			<img src="@/assets/trial/icon_bottom.png">
		</div>
		<CarModel2 :show="showCarModel" :firstModelId="firstModelId" :parentIfCruise="form.if_cruise" v-on:edit="handCarModel"></CarModel2>
		
		<!-- 超级巡航 -->
		<div class="cruise">
			<div class="cruise__title">
				SUPER CRUISE
			</div>
			<div class="cruise__init">
				参与体验超级智能驾驶系统（super cruise)
			</div>
			<div class="cruise__radio">
				<img
					@click="form.if_cruise = 1"
					v-if="form.if_cruise == 0"
					src="@/assets/trial/icon_radio.png"
				>
				<img
					@click="form.if_cruise = 0"
					v-if="form.if_cruise != 0"
					src="@/assets/trial/icon_radio_checked.png"
				>
			</div>
		</div>
		
		<!-- 标题 -->
		<div style="height: 50px;"></div>
		<div class="trialadd__title">
			预约车辆
		</div>
		<div class="caropts">
			<div class="caropts__empty" v-if="license.length == []">暂无可预约车辆</div>
			<div
				:class="['caropt', form.license_id == item.id ? 'caropt--active' : '']"
				v-for="item in license"
				:key="item.id"
				@click="handLicense(item)"
			>
				<div class="caropt__no">
					{{item.name}}
				</div>
				<div class="caropt__time" v-if="item.id == form.license_id">
					{{time_range}}
				</div>
			</div>
		</div>
		
		<!-- 标题 -->
		<div style="height: 50px;"></div>
		<div class="trialadd__title">
			邀请函
		</div>
		<div class="invitation">
			<div class="invitation__empty" v-if="invitation.id == 0">尚无关联的邀请函</div>
			<div class="inviter" @click="handInviter(invitation)"  v-if="invitation.id > 0">
				<div class="inviter__left">
					<div class="inviter__user">
						<span>{{invitation.customer_name}}</span><span>{{invitation.customer_mobile}}</span>
					</div>
					<div class="inviter__car">
						凯迪拉克 {{invitation.model_name}}
					</div>
					<div class="inviter__time">
						{{ invitation.appointment_time}}
					</div>
				</div>
				<div class="inviter__right">
					<img src="@/assets/common/icon_right.png">
				</div>
			</div>
		</div>
		
		<div  style="height: 91px;" :style="{'top':(docmHeight - 91) + 'px'}" class="trialadd__addbtn__box">
			<div class="trialadd__addbtn" @click="handTrialAdd">
				确定
			</div>
		</div>
		
		<!-- - 日期 -->
		<van-popup v-model="timePopup" position="bottom" :style="{'backgroundColor':'#575757'}">
			<div class="trialadd__timelist__box">
				<div class="trialadd__timelist">
					<div 
						@click="handTimeUse(item)"
						:class="[
							'trialadd__timelist__item',
							item.used == 1 ? 'trialadd__timelist__item--disabled' : '',
							item.time_range == time_range_checked ? 'trialadd__timelist__item--active' : ''
						]" v-for="item,key in timeList" :key="item.id"
						v-if="key < 6"
					>
						{{item.time_range}}
					</div>
					<div class="trialadd__timelist__line"></div>
					<div
						@click="handTimeUse(item)"
						:class="[
							'trialadd__timelist__item',
							item.used == 1 ? 'trialadd__timelist__item--disabled' : '',
							item.time_range == time_range_checked ? 'trialadd__timelist__item--active' : ''
						]" v-for="item,key in timeList" :key="item.id"
						v-if="key >= 6"
					>
						{{item.time_range}}
					</div>
				</div>
				<div class="trialadd__timelist__btn__box">
					<div class="trialadd__timelist__btn" @click="handTime">
						确定
					</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
import CarModel2 from '@/components/carmodel2.vue'
import { getDateTime4, getDateTime5,getDateTime3 } from '@/utils/utils.js'

export default {
	components: {
		CarModel2
	},
	data() {
		return {
			showCarModel: false,
			modelName: '',
			collocationName: '',
			license: [],
			timeList: [],
			time_range: '',
			time_range_checked: '',
			license_id: 0,
			firstModelId: 0,
			timePopup:false,
			form: {
				gender: 1,
				mobile: '',
				name: '',
				model_id: 0,
				collocation_id: 0,
				invitation_id: 0,
				if_cruise: 0,
				license_id: 0,
				short_time: 0,
			},
			invitation: {
				id: 0
			},
		};
	},
	mounted() {
		if (this.$route.query.phone) this.form.mobile = this.$route.query.phone
		if (this.$route.query.invitation_id) this.form.invitation_id = this.$route.query.invitation_id
		this.getInviterRelate()
	},
	computed: {    
			docmHeight() {
				return this.$store.state.height;
			}
		},
	methods: {
		// 车型选择
		handCarModelShow(e) {
			this.showCarModel = true
			setTimeout(() => {
				this.showCarModel = false
			}, 1000)
		},
		handCarModel(e) {
			this.form.model_id = e.modelId
			this.form.collocation_id = e.collocationId
			this.modelName = e.modelName
			this.collocationName = e.collocationName
			this.getCarList()
		},
		
		// 选择车辆
		handLicense(e) {
			this.license_id = e.id
			if (this.license_id != this.form.license_id) {
				this.time_range_checked = ''
			} else {
				this.time_range_checked = this.time_range
			}
			this.getTimeList()
		},
		
		// 选择时间
		handTimeUse(e) {
			if (e.used == 1) {
				return
			}
			this.time_range_checked = e.time_range
		},
		handTime() {
			if (this.time_range_checked == '') return
			this.time_range = this.time_range_checked
			this.form.license_id = this.license_id
			this.timePopup = false
		},
		
		// 试驾邀请
		handInviter(e) {
			this.$router.push({
				path: `/trial-invitation?id=${e.id}`
			})
		},
		
		// 车辆列表
		async getCarList(e) {
			this.license.length = 0
			this.license_id = 0
			this.form.license_id = 0
			this.time_range = ''
			this.time_range_checked = ''
			const {data: result} = await this.$http.post('/api/scsj/get_car_license', {
				model_id: this.form.model_id
			})
			if (result.code == 1 && result.data.res != '') {
				result.data.res.forEach(v => {
					this.license.push(v)
				})
			}
		},
		
		// 预约列表
		async getTimeList(e) {
			this.timeList.length = 0
			const fulldate = getDateTime4()
			const {data: result} = await this.$http.post('/api/scsj/get_reservation_list', {
				year: fulldate.substr(0, 4),
				month: fulldate.substr(5, 2),
				day: fulldate.substr(8, 2),
				license_id: this.license_id
			})
			if (result.code == 1) {
				result.data.res.forEach(v => {
					this.timeList.push(v)
				})
				this.timePopup = true
			}
		},
		
		// 关联邀请
		async getInviterRelate() {
			const {data: result} = await this.$http.post('/api/scsj/get_relate_invitation_1_6', {
				id: this.form.invitation_id,
				mobile: this.form.mobile
			})
			if (result.code == 1 && result.data.res) {
				this.form.invitation_id = result.data.res.id
				this.getInviterInfo()
			}
		},
		async getInviterInfo(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_scsj_invitation_info_1_7_2', {
				id: this.form.invitation_id
			})
			if (result.code == 1) {
				this.invitation = { ...this.invitation, ...result.data.res }
				this.invitation.appointment_time = getDateTime3(this.invitation.appointment_time)
				this.form.gender = result.data.res.customer_gender
				this.form.mobile = result.data.res.customer_mobile
				this.form.name = result.data.res.customer_name
				this.form.model_id = result.data.res.scsj_car_model_id
				this.firstModelId = result.data.res.scsj_car_model_id
			}
		},
		
		// 创建试驾
		async handTrialAdd(e) {
			this.form.short_time = getDateTime5(getDateTime4() + ' ' + this.time_range.split(' - ')[0])
			this.form.store_id = 0
			if(this.form.model_id == 0) return this.$toast.fail('请选择车型')
			if(this.form.name == '') return this.$toast.fail('请输入名称')
			const {data: result} = await this.$http.post('/api/scsj/create_scsj_1_6', this.form)
			if (result.code == 1) {
				this.$toast.success('创建成功')
				this.$router.replace({
					path: `/trial-succ?number=${result.data.res.number}`
				})
			} else {
				this.$toast.fail(result.msg)
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.trialadd{
	padding-bottom: 220px;
	&__title{
		padding: 0 32px;
		height: 80px;
		line-height: 80px;
		position: relative;
		font-size: 40px;
		font-family: HYQiHeiY3-55;
		color: #FFFFFF;
	}
}
.customer__gender{
	background: #222222;
	border-radius: 8px;
	position: absolute;
	top: 12px;
	right: 32px;
	display: flex;
	&__man{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 108px;
		height: 56px;
		img{
			width: 32px;
			height: 32px;
		}
	}
	&__woman{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 108px;
		height: 56px;
		img{
			width: 40px;
			height: 40px;
		}
	}
	&--active{
		background: #fff;
		border-radius: 8px;
	}
}
.trialadd__phone{
	margin: 24px auto 0;
	width: 686px;
	height: 88px;
	display: flex;
	align-items: center;
	background: #222;
	img{
		margin: 0 28px;
		width: 36px;
		height: 32px;
	}
	input{
		width: 570px;
		font-size: 32px;
		font-family: Regular;
		border: none;
		background-color: rgba(255, 255, 255, 0);
		color: #ECECEC;
	}
}
.trialadd__user{
	margin: 24px auto 0;
	width: 686px;
	height: 88px;
	display: flex;
	align-items: center;
	background: #222;
	img{
		margin: 0 28px;
		width: 36px;
		height: 32px;
	}
	input{
		width: 570px;
		font-size: 32px;
		border: none;
		background-color: rgba(255, 255, 255, 0);
		font-family: HYQiHeiY3-45;
		color: #ECECEC;
	}
}
.trialadd__carmodel{
	margin: 24px auto 0;
	width: 686px;
	height: 88px;
	display: flex;
	align-items: center;
	background: #222;
	font-size: 32px;
	font-family: HYQiHeiY3-45;
	color: #999;
	position: relative;
	img:first-child{
		margin: 0 28px;
		width: 44px;
		height: 36px;
	}
	img:last-child{
		position: absolute;
		right: 28px;
		top: 28px;
		width: 32px;
		height: 32px;
	}
}
.cruise{
	margin: 60px auto 0;
	width: 686px;
	height: 256px;
	background: url(https://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/bg_cruise.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	position: relative;
	&__title{
		padding-top: 32px;
		padding-left: 40px;
		font-size: 40px;
		font-family: HYQiHeiY3-65;
		color: #fff;
		line-height: 40px;
	}
	&__init{
		padding-top: 16px;
		padding-left: 40px;
		font-size: 28px;
		font-family: HYQiHeiY3-55;
		color: #fff;
		line-height: 40px;
	}
	&__radio{
		position: absolute;
		top: 40px;
		right: 40px;
		img{
			width: 48px;
			height: 48px;
		}
	}
}
.caropts{
	padding: 0 32px;
	&__empty{
		width: 100%;
		height: 172px;
		background: #303030;
		font-size: 32px;
		font-family: HYQiHeiY3-55;
		color: #919191;
		text-align: center;
		line-height: 172px;
	}
	.caropt{
		width: 100%;
		height: 92px;
		border: 1px solid #979797;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		text-align: center;
		margin-bottom: 32px;
		&__no{
			width: 100%;
			font-size: 40px;
			font-family: HYQiHeiY3-55;
			color: #FFFFFF;
			line-height: 48px;
		}
		&__time{
			width: 100%;
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			color: #FFFFFF;
			line-height: 34px;
		}
	}
	.caropt:last-child{
		margin-bottom: 0;
	}
	.caropt--active{
		background: #fa0037;
		border: 1px solid #fa0037;
	}
}
.invitation{
	padding: 0 32px;
	&__empty{
		width: 100%;
		height: 172px;
		background: #303030;
		font-size: 32px;
		font-family: HYQiHeiY3-55;
		color: #919191;
		text-align: center;
		line-height: 172px;
	}
	.inviter{
		margin-bottom: 30px;
		padding: 20px 32px;
		box-sizing: border-box;
		width: 100%;
		background: #373C46;
		border-radius: 12px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		&__user{
			font-size: 36px;
			font-family: HYQiHeiY3-45;
			color: #9B9B9B;
			line-height: 36px;
			span:first-child{
				color: #fff;
				margin-right: 10px;
			}
		}
		&__car{
			margin-top: 15px;
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			color: #9B9B9B;
			line-height: 28px;
		}
		&__time{
			margin-top: 15px;
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			color: #9B9B9B;
			line-height: 28px;
		}
		&__right{
			img{
				width: 40px;
				height: 32px;
			}
		}
	}
	.inviter:last-child{
		margin-bottom: 0;
	}
}
.trialadd__timelist{
	padding: 40px;
	height: 900px;
	overflow: auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	.trialadd__timelist__line{
		width: 100%;
		height: 1px;
		background: #686868;
		margin-bottom: 32px;
	}
	.trialadd__timelist__item{
		margin-bottom: 32px;
		width: 314px;
		height: 88px;
		text-align: center;
		line-height: 88px;
		border: 1px solid rgba(255, 255, 255, 0.3);
		font-size: 32px;
		font-family: Regular;
		color: #fff;
	}
	.trialadd__timelist__item--disabled{
		background: #6F6F6F;
		border: 1px solid #6F6F6F;
	}
	.trialadd__timelist__item--active{
		background: #fa0037;
		border: 1px solid #fa0037;
	}
}
.trialadd__timelist__btn__box{
	padding: 20px 0;
}
.trialadd__timelist__btn{
	margin: 0 auto;
	width: 670px;
	box-sizing: border-box;
	line-height: 88px;
	background: #fa0037;
	font-size: 32px;
	font-family: HYQiHeiY3-45;
	color: #F4F4F4;
	text-align: center;
}
.trialadd__addbtn__box{
	padding: 0 32px;
	height: 150px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #222222;
	width: 100%;
	box-sizing: border-box;
	position: fixed;
	bottom: 0;
}
.trialadd__addbtn{
	width: 100%;
	line-height: 88px;
	background: #fa0037;
	font-size: 32px;
	font-family: HYQiHeiY3-45;
	color: #F4F4F4;
	text-align: center;
}
</style>
