<template>
	<div class="carmodel2">
		<!-- 车型选择弹窗 -->
		<van-popup  v-model="carModelPopup" :style="{'backgroundColor': '#575757'}"  position="bottom">
			<div class="carmodel__btns">
				<div @click="handCarModelClose">取消</div>
				<div @click="handCarModelOk">确定</div>
			</div>
			<div class="carmodel__list">
				<van-picker
					item-height="40"
					:columns="modelpicerlist"
					value-key="name" 
					@change="handCarModelEdit"
					class="picker-div"
				>
				</van-picker>
			</div>
		</van-popup>
	</div>
</template>

<script>

export default {
	name: 'CarModel2',
	props: {
		show: Boolean,
		firstModelId: Number,
		parentIfCruise: Number
	},
	data() {
		return {
			myFirstModelId: 0,
			carmodelList: [],
			ifCruise: 0,
			modelId: 0,
			collocationId: 0,
			modelList: [],
			collocationList: [],
			modelpicerlist:[],
			carModelPopup:false,
			modelForm: {
				ifCruise: 0,
				modelId: 0,
				modelName: '',
				collocationId: 0,
				collocationName: ''
			}
		};
	},
	computed:{
	},
	watch: {
		show(e) {
			if (this.show) {
				this.carModelPopup=true
			}
		},
		firstModelId(e) {
			this.myFirstModelId = this.firstModelId
			if (this.carmodelList.length == 0) {
				return
			}
			this.handIfCruiseRefresh()
			this.modelId = this.myFirstModelId
			this.handCarModelRefresh()
			setTimeout(() => {
				this.handCarModelOk()
			}, 1000)
		},
		parentIfCruise(e) {
			console.log('11111',this.parentIfCruise)
			if (this.ifCruise == this.parentIfCruise) {
				return
			}
			if (this.parentIfCruise == 0) {
				this.modelpicerlist = this.carmodelList.map(item => {
					item.children = item.scsj_car_collocation
					return item
				})
			}else{
				this.modelpicerlist = []
			this.carmodelList.forEach(item => {
				const scsjlist =  item.scsj_car_collocation.filter(v => v.if_cruise == 1)
				if(scsjlist.length != 0){
					item.children = scsjlist
					this.modelpicerlist.push(item)
				}
			})
			}
			this.ifCruise = this.parentIfCruise
			this.modelId = 0
			this.collocationId = 0
			setTimeout(() => {
				this.handCarModelRefresh()
			}, 100)
			setTimeout(() => {
				this.handCarModelOk()
			}, 300)
		},
		modelId(e) {
			this.collocationList.length = 0
			const modelList = this.carmodelList.filter(v => v.id == this.modelId)
			
			if (modelList.length == 0) {
				this.collocationId = 0
				return
			}
			modelList[0].scsj_car_collocation.forEach(v => {
				if (this.ifCruise == 0 || v.if_cruise == this.ifCruise) {
					this.collocationList.push({
						id: v.id,
						name: v.name
					})
				}
			})
			if (this.collocationList.length == 0) {
				this.collocationId = 0
				return
			}
			if (this.collocationList.filter(v => v.id == this.collocationId).length == 0) {
				this.collocationId = this.collocationList[0].id
			}
		},
	},
	mounted(){
		this.getCarModelList()
	},
	methods: {
		handCarModelEdit(picker,value,index) {
			this.modelpicerlist.forEach(item => {
				if(item.name == value[0]){
					this.modelId = item.id
					item.children.forEach(v => {
						if(v.name == value[1]){
							this.collocationId = v.id
						}
					})
				}
			})
		},
		handCarModelClose(e) {
			this.carModelPopup=false
		},
		handCarModelOk(e) {
			this.modelForm.ifCruise = this.ifCruise
			this.modelForm.modelId = this.modelId
			this.modelForm.collocationId = this.collocationId
			this.carmodelList.forEach(v => {
				if (v.id == this.modelForm.modelId) {
					this.modelForm.modelName = v.name
					v.scsj_car_collocation.forEach(vc => {
						if (vc.id == this.modelForm.collocationId) {
							this.modelForm.collocationName = vc.name
						}
					})
				}
			})
			this.$emit('edit', this.modelForm)
			this.handCarModelClose()
		},
		
		handIfCruiseRefresh(e) {
			if (this.myFirstModelId == 0 || this.carmodelList.length == 0) {
				return
			}
			const modelList = this.carmodelList.filter(v => v.id == this.myFirstModelId)
			if (modelList.length == 0) {
				return
			}
			modelList[0].scsj_car_collocation.forEach(v => {
				this.ifCruise = v.if_cruise
			})
		},
		
		// 车型列表
		async getCarModelList(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_car_model_1_5', {
				store_id: 0
			})
			if (result.code == 1) {
				this.modelpicerlist = result.data.res.map(item => {
					item.children = item.scsj_car_collocation
					return item
				})
				result.data.res.forEach((v, k) => {
					this.carmodelList.push(v)
				})
				this.handIfCruiseRefresh()
				this.handCarModelRefresh()
				if (this.myFirstModelId > 0) {
					setTimeout(() => {
						this.handCarModelOk()
					}, 1000)
				}
			}
		},
		handCarModelRefresh(e) {
			this.modelList.length = 0
			this.carmodelList.forEach(v => {
				if (v.scsj_car_collocation.filter(vc => this.ifCruise == 0 || vc.if_cruise == this.ifCruise).length > 0) {
					this.modelList.push({
						id: v.id,
						name: v.name
					})
				}
			})
			if (this.modelList.length == 0) {
				this.modelId = 0
				return
			}
			if (this.modelList.filter(v => v.id == this.modelId).length == 0) {
				this.modelId = this.modelList[0].id
			}
		},
	}
}
</script>

<style lang="scss">
.carlist{
	margin: 40px auto 0;
	width: 750px;
	.car{
		margin-left: 15px;
		margin-right: 15px;
		border: 1px solid rgba(255, 255, 255, 0.3);
		text-align: center;
		padding: 28px 0;
		&__name{
			overflow: hidden;
			margin-bottom: 10px;
			font-size: 36px;
			font-family: HYQiHeiY3-55;
			color: #fff;
			line-height: 40px;
		}
		&__model{
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			color: #ECECEC;
			line-height: 32px;
		}
		&__add{
			font-size: 36px;
			font-family: HYQiHeiY3-55;
			color: #fff;
			line-height: 82px;
		}
	}
	.car--active{
		background: #FA0037;
		border: 1px solid #FA0037;
	}
	&__empty{
		padding-bottom: 50px;
		text-align: center;
		image{
			width: 88px;
			height: 54px;
		}
		&__init{
			margin-top: 26px;
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			color: #575757;
			line-height: 34px;
		}
		&__add{
			margin: 98px auto 0;
			width: 260px;
			height: 72px;
			border: 1px solid rgba(151, 151, 151, 0.3);
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			color: #ECECEC;
			line-height: 72px;
		}
	}
}
.carddd{
	background: #E8E8E8;
	width: 670px;
	border-radius: 20px;
	&__title{
		padding-top: 60px;
		text-align: center;
		font-size: 40px;
		font-family: HYQiHeiY3-65;
		color: #101010;
		line-height: 40px;
	}
	&__opts{
		margin-top: 60px;
		&__select{
			margin: 0 auto;
			width: 590px;
			height: 92px;
			line-height: 92px;
			border: 1px solid rgba(87 ,87 , 87, 0.3);
			text-align: center;
			position: relative;
			font-size: 32px;
			font-family: HYQiHeiY3-55;
			color: #333;
			image{
				position: absolute;
				right: 30px;
				top: 30px;
				width: 32px;
				height: 32px;
			}
		}
		&__input{
			margin: 20px auto 0;
			width: 590px;
			height: 92px;
			border: 1px solid rgba(87 ,87 , 87, 0.3);
			input{
				width: 100%;
				height: 100%;
				text-align: center;
				font-size: 32px;
				font-family: HYQiHeiY3-55;
				color: #333;
			}
		}
	}
	&__btns{
		margin-top: 60px;
		border-top: 1px solid #D7D7D7;
		display: flex;
		div{
			text-align: center;
			width: 50%;
			font-size: 36px;
			font-family: HYQiHeiY3-55;
			color: #323232;
			line-height: 90px;
		}
		div:first-child{
			border-right: 1px solid #D7D7D7;
		}
		div:last-child{
			color: #2C28E8;
		}
	}
}
.carmodel__btns{
	padding: 46px 40px 0;
	display: flex;
	justify-content: space-between;
	div{
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		color: #B9B9B9;
		line-height: 36px;
	}
	div:last-child{
		color: #F3C846;
	}
}
.carmodel__list{
	padding: 0 54px 200px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	.carmodel__item{
		margin-top: 30px;
		width: 300px;
		padding: 40px 24px 16px;
		text-align: center;
		&__logo{
			image{
				width: 100%;
			}
		}
		&__name{
			margin-top: 12px;
			font-size: 30px;
			font-family: Regular;
			color: #ECECEC;
			line-height: 30px;
		}
	}
	.carmodel__item--active{
		background: rgba(0, 0, 0, 0.1);
		border-radius: 14px;
	}
}
.picker-div {
	width: 100%;
	height: 300px;
	background-color: #575757;
	.van-picker__mask{
		background-image:none !important;
	}
	.van-ellipsis{
			color: #ffffff !important;
		}
}
.carmodel__list__item {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 40px;
	font-family: HYQiHeiY3-65;
	color: #fff;
}
</style>